/*
---
DEFAULT OPTIONS
---
*/

.lh--1 {
  line-height: 1 !important;
}
.lh--115  {
  line-height: 1.15 !important;
}
.lh--12 {
  line-height: 1.2 !important;
}
.lh--14 {
  line-height: 1.4 !important;
}
.lh--15 {
  line-height: 1.5 !important;
}
.lh--16 {
  line-height: 1.6 !important;
}
.lh--2 {
  line-height: 2 !important;
}

.ls--002 {
  letter-spacing: -0.02em !important;
}
.ls--0015 {
  letter-spacing: -0.015em !important;
}
.ls--001 {
  letter-spacing: -0.01em !important;
}
.ls--0 {
  letter-spacing: 0;
}
.ls--01 {
  letter-spacing: 0.01em !important;
}

.mt--02 {
  margin-top: 0.2vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (0.2 * 19.2px) !important;
  }
}
.mt--05 {
  margin-top: 0.5vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (0.5 * 19.2px) !important;
  }
}
.mt--06 {
  margin-top: 0.6vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (0.6 * 19.2px) !important;
  }
}
.mt--08 {
  margin-top: 0.8vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (0.8 * 19.2px) !important;
  }
}
.mt--10 {
  margin-top: 1vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (1 * 19.2px) !important;
  }
}
.mt--12 {
  margin-top: 1.2vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (1.2 * 19.2px) !important;
  }
}
.mt--16 {
  margin-top: 1.6vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (1.6 * 19.2px) !important;
  }
}
.mt--20 {
  margin-top: 2vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (2 * 19.2px) !important;
  }
}
.mt--25 {
  margin-top: 2.5vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (2.5 * 19.2px) !important;
  }
}
.mt--30 {
  margin-top: 3vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (3 * 19.2px) !important;
  }
}
.mt--40 {
  margin-top: 4vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (4 * 19.2px) !important;
  }
}
.mt--50 {
  margin-top: 5vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (5 * 19.2px) !important;
  }
}
.mt--60 {
  margin-top: 6vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (6 * 19.2px) !important;
  }
}
.mt--80 {
  margin-top: 8vmax !important;

  @media screen and (min-width: 1920px) {
    margin-top: (8 * 19.2px) !important;
  }
}

.mb--0 {
  margin-bottom: 0 !important;
}
.mb--02 {
  margin-bottom: 0.2vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (0.2 * 19.2px) !important;
  }
}
.mb--06 {
  margin-bottom: 0.6vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (0.6 * 19.2px) !important;
  }
}
.mb--08 {
  margin-bottom: 0.8vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (0.8 * 19.2px) !important;
  }
}
.mb--10 {
  margin-bottom: 1vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (1 * 19.2px) !important;
  }
}
.mb--12 {
  margin-bottom: 1.2vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (1.2 * 19.2px) !important;
  }
}
.mb--16 {
  margin-bottom: 1.6vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (1.6 * 19.2px) !important;
  }
}
.mb--20 {
  margin-bottom: 2vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (2 * 19.2px) !important;
  }
}
.mb--25 {
  margin-bottom: 2.5vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (2.5 * 19.2px) !important;
  }
}
.mb--30 {
  margin-bottom: 3vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (3 * 19.2px) !important;
  }
}
.mb--40 {
  margin-bottom: 4vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (4 * 19.2px) !important;
  }
}
.mb--50 {
  margin-bottom: 5vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (5 * 19.2px) !important;
  }
}
.mb--60 {
  margin-bottom: 6vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (6 * 19.2px) !important;
  }
}
.mb--80 {
  margin-bottom: 8vmax !important;

  @media screen and (min-width: 1920px) {
    margin-bottom: (8 * 19.2px) !important;
  }
}

.ml--auto {
  margin-left: auto !important;
}

.mr--auto {
  margin-right: auto !important;
}

.mb-in {
  > :not(:last-child) {
    margin-bottom: ~'max(1.5vmax, 29px)';
  }
  & + & {
    margin-top: 3vmax;
  }
}

.p--0 {
  padding: 0 !important;
}

.pt--10 {
  padding-top: 1vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (1 * 19.2px) !important;
  }
}
.pt--16 {
  padding-top: 1.6vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (1.6 * 19.2px) !important;
  }
}
.pt--20 {
  padding-top: 2vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (2 * 19.2px) !important;
  }
}
.pt--25 {
  padding-top: 2.5vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (2.5 * 19.2px) !important;
  }
}
.pt--40 {
  padding-top: 4vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (4 * 19.2px) !important;
  }
}
.pt--50 {
  padding-top: 5vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (5 * 19.2px) !important;
  }
}
.pt--60 {
  padding-top: 6vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (6 * 19.2px) !important;
  }
}
.pt--80 {
  padding-top: 8vmax !important;

  @media screen and (min-width: 1920px) {
    padding-top: (8 * 19.2px) !important;
  }
}

.pb--10 {
  padding-bottom: 1vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (1 * 19.2px) !important;
  }
}
.pb--16 {
  padding-bottom: 1.6vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (1.6 * 19.2px) !important;
  }
}
.pb--20 {
  padding-bottom: 2vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (2 * 19.2px) !important;
  }
}
.pb--25 {
  padding-bottom: 2.5vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (2.5 * 19.2px) !important;
  }
}
.pb--30 {
  padding-bottom: 3vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (3 * 19.2px) !important;
  }
}
.pb--40 {
  padding-bottom: 4vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (4 * 19.2px) !important;
  }
}
.pb--50 {
  padding-bottom: 5vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (5 * 19.2px) !important;
  }
}
.pb--60 {
  padding-bottom: 6vmax !important;

  @media screen and (min-width: 1920px) {
    padding-bottom: (6 * 19.2px) !important;
  }
}

.pl--0 {
  padding-left: 0 !important;
}
.pl--02 {
  padding-left: 0.2em !important;
}
.pl--06 {
  padding-left: 0.6em !important;
}
.pl--08 {
  padding-left: 0.8em !important;
}
.pl--10 {
  padding-left: 1em !important;
}
.pl--12 {
  padding-left: 1.2em !important;
}
.pl--16 {
  padding-left: 1.6em !important;
}
.pl--20 {
  padding-left: 2em !important;
}
.pl--25 {
  padding-left: 2.5em !important;
}
.pl--30 {
  padding-left: 3em !important;
}
.pl--40 {
  padding-left: 4em !important;
}

.pr--0 {
  padding-right: 0 !important;
}
.pr--02 {
  padding-right: 0.2em !important;
}
.pr--06 {
  padding-right: 0.6em !important;
}
.pr--08 {
  padding-right: 0.8em !important;
}
.pr--10 {
  padding-right: 1em !important;
}
.pr--12 {
  padding-right: 1.2em !important;
}
.pr--16 {
  padding-right: 1.6em !important;
}
.pr--20 {
  padding-right: 2em !important;
}
.pr--25 {
  padding-right: 2.5em !important;
}
.pr--30 {
  padding-right: 3em !important;
}
.pr--40 {
  padding-right: 4em !important;
}

.w--300 {
  font-weight: 300 !important;
}
.w--400 {
  font-weight: 400 !important;
}
.w--500 {
  font-weight: 500 !important;
}
.w--600 {
  font-weight: 600 !important;
}
.w--700 {
  font-weight: 700 !important;
}

.ff--1 {
  font-family: @font1!important;
}

.bg-dark {
  background-color: #212121;
}

.ta--l {
  text-align: center;
  @media screen and (min-width: @xsm-BP) {
    text-align: left;
  }
}

.ta--r {
  text-align: center;
  @media screen and (min-width: @xsm-BP) {
    text-align: right;
  }
}

.ta--m-l {
  @media screen and (max-width: @xsm-BP) {
    text-align: left !important;
  }
}
