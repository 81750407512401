.button {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  font-size: clamp(15px, 1vw, 17px);
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.025em;
  padding: 1em 1.5em;
  position: relative;
  transition:
    box-shadow 0.08s ease-out,
    background 0.1s linear;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;

  &[disabled]:not(.isLoading) {
    box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 0.05);
    color: rgba(var(--border-primary), 0.15);
    background: rgba(var(--border-primary), 0.05);
    transition: all 140ms ease-out;
    cursor: not-allowed;
  }

  span {
    pointer-events: none;
  }
}

// SIZES
.button.medium {
  font-size: 15px;
  font-size: clamp(15px, 1vw, 17px);
  padding: 1em 1.5em;
}

.button.small {
  font-size: 14px;
  font-size: clamp(14px, 0.95vw, 16px);
  padding: 0.75em 1.25em;
}

.button.extrasmall {
  font-size: 14px;
  font-size: clamp(14px, 0.95vw, 16px);
  padding: 0.5em 0.75em;
}

.button.done {
  font-size: 14px;
  font-size: clamp(14px, 0.95vw, 16px);
  padding: 0.5em 1.25em;
  border-radius: 3px !important;
}

// VARIANTS
.primary {
  box-shadow: 0px 0px 0px 3px rgba(var(--border-primary), 0.12);

  @media screen and (min-width: 480px) {
    &:hover:not([disabled]):not(.isLoading) {
      box-shadow: 0px 0px 0px 5px rgba(var(--border-primary), 0.1);
      filter: brightness(1.2);
    }

    &:active,
    &.active {
      filter: brightness(0.8);
    }
  }
}

.secondary {
  background: rgba(var(--border-primary), 0);
  box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 0.08);
  color: var(--text-primary);

  @media screen and (min-width: 480px) {
    &:hover:not([disabled]):not(.isLoading) {
      background: rgba(var(--border-primary), 0.08);
    }

    &:active,
    &.active {
      background: rgba(var(--border-primary), 0.2);
      box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 0.2);
    }
  }
}

.tertiary {
  background: rgba(var(--border-primary), 0);
  box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 0);
  color: var(--text-primary);

  @media screen and (min-width: 480px) {
    &:hover:not([disabled]):not(.isLoading) {
      background: rgba(var(--border-primary), 0.08);
      box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 0.08);
    }

    &:active,
    &.active {
      background: rgba(var(--border-primary), 0.2);
      box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 0.2);
    }
  }

  &[disabled]:not(.isLoading) {
    background: transparent;
  }
}

.extra {
  @media screen and (min-width: 480px) {
    &:hover:not([disabled]):not(.isLoading) {
      filter: brightness(1.2);
    }

    &:active,
    &.active {
      filter: brightness(0.8);
    }
  }
}

.danger {
  background: rgba(228, 62, 62, 0);
  box-shadow: 0px 0px 0px 2px rgba(228, 62, 62, 0);
  color: rgba(228, 62, 62, 1);

  @media screen and (min-width: 480px) {
    &:hover:not([disabled]):not(.isLoading) {
      background: rgba(228, 62, 62, 0.08);
      box-shadow: 0px 0px 0px 2px rgba(228, 62, 62, 0.08);
    }

    &:active,
    &.active {
      background: rgba(228, 62, 62, 0.2);
      box-shadow: 0px 0px 0px 2px rgba(228, 62, 62, 0.2);
    }
  }
}

.tertiaryBasic {
  background-color: transparent;
  color: var(--text-primary);
  padding: 0 !important;

  &:hover {
    text-decoration: underline;
  }
}

.basic {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #0e1c40;
  font-weight: 400;
  padding: 0;
  text-decoration: underline;

  @media screen and (min-width: 480px) {
    &:hover {
      color: var(--text-primary);
    }
  }

  &:active,
  &.active {
    color: var(--text-primary);
  }
}

.done {
  @media screen and (min-width: 480px) {
    &:hover:not([disabled]):not(.isLoading) {
      filter: brightness(1.2);
    }

    &:active,
    &.active {
      filter: brightness(0.8);
    }
  }
}

.edit {
  color: var(--text-black);
  padding: 0.75em 1.25em;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width: 480px) {
    &:hover {
      background: var(--bg-light-blue);
      color: var(--text-primary);
    }
  }
  &:active,
  &.active {
    background: var(--bg-light-blue);
    color: var(--text-primary);
  }

  &[disabled]:not(.isLoading) {
    box-shadow: none;
    color: var(--text-black);
    background: transparent;
    opacity: 0.75;

    svg {
      color: inherit !important;
    }
  }
}

// shape

.rounded {
  border-radius: 5px;
}

.round  {
  border-radius: 50%;
  height: 40px;
  justify-content: center;
  min-width: 40px;
  padding: 0;
  width: 40px;

  &:after {
    border-radius: 50%;
  }
}
