.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.\-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.\-translate-y-3\/4 {
  --tw-translate-y: -75%;
}

.\-translate-y-full {
  --tw-translate-y: -100%;
}

.\-translate-y-3\/2 {
  --tw-translate-y: -150%;
}

.translate-y-2px {
  --tw-translate-y: 2px;
}

.translate-y-4 {
  --tw-translate-y: 1em;
}

.translate-4 {
  --tw-translate-x: 1em;
  --tw-translate-y: 1em;
}

.flip {
  --tw-scale-x: -1;
  --tw-scale-y: 1;
}

.rotate-90 {
  --tw-rotate: 90deg;
}
