@import (css) '@reach/listbox/styles.css';
@import (css) '@reach/dialog/styles.css';
@import (css) '@reach/menu-button/styles.css';

@import 'variables.less';
@import 'mixins.less';
@import 'reset.less';
@import 'general.less';
@import 'default.less';
@import 'colors.less';
@import 'UI.less';
@import 'palette.less';

@import 'component.link-text.less';
@import 'component.cp-breadcrumbs.less';
@import 'component.nestyd.less';
@import 'component.opanelcity.less';
@import 'component.snackbar.less';
@import 'component.switcher.less';
@import 'component.tooltip.less';

///

.sh-down-1 {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    top: 100%;
    height: 4px;
    background: linear-gradient(
      rgba(9, 30, 68, 0.12) 0px,
      rgba(9, 30, 68, 0.12) 1px,
      rgba(9, 30, 68, 0.08) 1px,
      rgba(9, 30, 68, 0) 4px
    );
  }
}

.header-wrap {
  padding: 0 clamp(12px, 1.8vw, 35px);
}

.maxWidthDefault {
  max-width: 1920px;
}

.maxWidthSmall {
  max-width: 60em;
}

.maxWithMedium {
  max-width: 66em;
}

.paddingDefault {
  @media screen and (min-width: 1440px) {
    padding: 0 min(9vw, 172px);
  }
}

.paddingLarge {
  @media screen and (min-width: 1440px) {
    padding: 0 min(7.25vw, 140px);
  }
}

.saleWrap {
  display: none;

  @media screen and (min-width: 880px) {
    align-items: center;
    background: #2162d2;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    height: inherit;
    padding: 0 ~'calc(1% + 12px)';
    height: 60px;

    .sale__extra {
      background: #091427;
      border-radius: 4px;
      padding: 0.25em 0.5em;
      margin-left: 0.5em;
      line-height: 1;
    }
    .tc--extra {
      color: #b8cff6;
    }
  }
}

.saleWrap {
  display: none;

  @media screen and (min-width: 880px) {
    align-items: center;
    background: #2162d2;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    height: inherit;
    padding: 0 ~'calc(1% + 12px)';
    height: 60px;

    .sale__extra {
      background: #091427;
      border-radius: 4px;
      padding: 0.25em 0.5em;
      margin-left: 0.5em;
      line-height: 1;
    }
    .tc--extra {
      color: #b8cff6;
    }
  }
}

.columnWrap {
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 5%;
  margin: 0 auto;
  padding: 0 5vw;
}

.maxWidthDefault {
  max-width: 1920px;
}

.maxWidthSmall {
  max-width: 60em;
}

.maxWithMedium {
  max-width: 66em;
}

.paddingDefault {
  @media screen and (min-width: 1440px) {
    padding: 0 min(9vw, 172px);
  }
}

.paddingLarge {
  @media screen and (min-width: 1440px) {
    padding: 0 min(7.25vw, 140px);
  }
}

/*
-----
column
-----
*/

/*
---
modul column BASIC
---
*/

.A_column__wrap {
  align-items: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 4%;
  grid-row-gap: 1.5em;
  margin: auto;
  max-width: 77em;
  padding: 0 10px;
  width: 100%;

  @media screen and (max-width: @xsm-BP) {
    text-align: center;
  }

  @media screen and (min-width: @xsm-BP) {
    padding: 0 16px;
  }
}

.A_column__wrap + .A_column__wrap {
  margin-top: 2em;
}

.A_column__wrapper {
  width: 100%;
  box-sizing: border-box;
  max-width: 77em * 0.75;
  margin: 0 auto;

  @media screen and (min-width: @xsm-BP) {
    max-width: 100%;
  }
}

///
//Ceník
///

@pricelist--BP-1: 999px;
@pricelist--BP-2: 550px;

.A_column__wrap--pricelist {
  grid-template-columns: 1fr;
  grid-row-gap: 4em !important;
  align-items: flex-start;

  @media screen and (min-width: @pricelist--BP-2) {
    align-items: baseline;
    grid-template-columns: 1fr 1fr;
    padding-bottom: ~'min(5vmax, 96px)';
  }

  @media screen and (min-width: @pricelist--BP-1) {
    display: flex !important;
    grid-gap: 0 !important;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 ~'min(10.875vw, 208px)';
  }
}

.A_column__wrapper--pricelist {
  background-color: var(--bg-white);
  border: 2px solid rgba(var(--border-primary), 0.05);
  border-radius: 5px;
  box-shadow: 0 0 11px 0 rgba(0, 86, 195, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: @pricelist--BP-1) {
    transition: all 0.5s ease;
    flex-grow: 1;
    flex-basis: 0;

    &:not(.disabled):hover {
      transform: translateY(-25px);
      box-shadow: 0 25px 55px -5px rgba(var(--border-primary), 0.15),
        0 0 0 2px rgba(var(--border-primary), 0.04);
    }
  }
}

.A_column__wrapper--pricelist + .A_column__wrapper--pricelist {
  @media screen and (min-width: @pricelist--BP-1) {
    margin-left: 1.3em;
  }
}

.A_column__wrapper--pricelist--extra {
  border: 2px solid @color-variable-1;

  @media screen and (min-width: @pricelist--BP-1) {
    align-self: flex-start;
    transform: translateY(-57px);
    flex-grow: 1.22;

    &:not(.disabled):hover {
      transform: translateY(-65px);
    }

    .A_pricelist__point {
      max-width: 230px;
      margin: auto;
    }
  }
}

.A_column__wrapper--pricelist--full {
  max-width: 560px;
  @media screen and (min-width: @pricelist--BP-2) {
    grid-column: span 2;
  }
  @media screen and (min-width: @pricelist--BP-1) {
    grid-column: span 4;
  }
}

.A_pricelist-ps {
  padding: 3em 7% 3em 9%;
}

.A_pricelist__point {
  .A_pricelist__title {
    display: none;
  }

  @media screen and (max-width: @xsm-BP) {
    .A_pricelist__title {
      padding: 1.5em 0.5em 0;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
    }

    .A_pricelist__titleElement {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:after {
        content: '';
        display: block;
        color: @color-variable-1;
        width: 14px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 44 44' xml:space='preserve'%3E%3Cstyle%3E.st0%7Bfill:none;stroke:%231480DB;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%3C/style%3E%3Cpolyline class='st0' points='11,4 29,22 11,40'/%3E%3C/svg%3E");
        background-size: cover;
        background-repeat: no-repeat;
        transition: transform 0.4s ease-out;
      }
    }
    .A_pricelist__services {
      display: none;
    }
  }
}

.A_pricelist__point--active {
  @media screen and (max-width: @xsm-BP) {
    .A_pricelist__titleElement {
      &:after {
        transform: rotate(90deg);
      }
    }
    .A_pricelist__services {
      display: block;
    }
  }
}

.A_pricelist__price {
  border-top: 2px solid #f4f8fd;
  border-bottom: 2px solid #f4f8fd;
}

.A_pricelist__top-offer {
  background: url('../asset/top-offer-background.png') bottom center no-repeat;
  background-size: cover;

  svg {
    margin: 0 0.75em 0.25em 0.75em;
    opacity: 0.8;
    width: 1em;
  }
}

.A_pricelist__services {
  text-align: left !important;

  p {
    align-items: center;
    .font-size(@font-size: 15, @line: 22);
    letter-spacing: -0.025em;
    display: grid;
    color: var(--text-black);
    grid-template-columns: 1em auto;
    grid-column-gap: 1.25em;

    &:before {
      content: '';
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120.55 108.75'%3E%3Cdefs%3E%3Cstyle%3E.done-icon%7Bfill:none;stroke:%230060d9;stroke-miterlimit:10;stroke-width:24px%7D%3C/style%3E%3C/defs%3E%3Cpath class='done-icon' d='M8.71 53.04L44.21 90.54 111.21 7.54'/%3E%3C/svg%3E");
    }
  }
  div + div,
  & + & {
    margin-top: 0.9em !important;
  }
}

.A_pricelist__services--mini {
  p {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120.55 108.75'%3E%3Cdefs%3E%3Cstyle%3E.done-icon%7Bfill:none;stroke:%23003271;stroke-miterlimit:10;stroke-width:24px%7D%3C/style%3E%3C/defs%3E%3Cpath class='done-icon' d='M8.71 53.04L44.21 90.54 111.21 7.54'/%3E%3C/svg%3E");
    }
  }
}

.A_pricelist__services--no {
  p {
    color: var(--text-dark-black);

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96.52 96.52'%3E%3Cdefs%3E%3Cstyle%3E.icon-close%7Bfill:none;stroke:%237e95b2;stroke-miterlimit:10;stroke-width:16px%7D%3C/style%3E%3C/defs%3E%3Cpath class='icon-close' d='M5.66 5.66L90.87 90.87'/%3E%3Cpath class='icon-close' d='M5.66 90.87L90.87 5.66'/%3E%3C/svg%3E");
    }
  }
}

///
//Invoice
///

.m-w--52 {
  max-width: 52em;
}
.m-w--47 {
  max-width: 47em;
}
.m-w--40  {
  max-width: 40em !important;
}

.m-w--28  {
  max-width: 28em !important;
}

.md\:m-w--35  {
  @media screen and (min-width: @md-BP) {
    max-width: 35em !important;
  }
}

.md\:m-w--47  {
  @media screen and (min-width: @md-BP) {
    max-width: 47em !important;
  }
}

.lg\:m-w--30  {
  @media screen and (min-width: @lg-BP) {
    max-width: 30em !important;
  }
}

.lg\:m-w--35  {
  @media screen and (min-width: @lg-BP) {
    max-width: 35em !important;
  }
}

.lg\:m-w--47  {
  @media screen and (min-width: @lg-BP) {
    max-width: 47em !important;
  }
}

.lg\:m-w--58  {
  @media screen and (min-width: @lg-BP) {
    max-width: 58em !important;
  }
}

.m-w--29  {
  max-width: 29em;
}

.m-w--32  {
  max-width: 32em;
}

.m-w--31 {
  max-width: 31em;
}

.m-w--34 {
  max-width: 34em;
}

.m-w--35 {
  max-width: 35em;
}

.m-w--13 {
  max-width: 13em;
}

.m-w--15 {
  max-width: 15em;
}

.m-w--17 {
  max-width: 17em;
}

.m-w--18 {
  max-width: 18em;
}

.m-w--20 {
  max-width: 20em !important;
}

.m-w--75 {
  max-width: 75em;
}

@media screen and (min-width: @md-BP) {
  .md\:m-w--17 {
    max-width: 17em;
  }
}

.A_column__wrapper--invoice-info {
  align-items: baseline;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em 1em;

  @media screen and (min-width: 680px) {
    grid-template-columns: 2fr 1fr;
  }
}

.A_invoice-box {
  @media screen and (max-width: 680px) {
    text-align: center;
  }

  @media screen and (min-width: 680px) {
    grid-template-columns: 2fr 1fr;
    padding: 0 1.5em;
  }
}

.A_invoice-box--border {
  border: 2px solid rgba(108, 157, 217, 0.15);
  border-radius: 8px;
  padding: 1.25em 1.5em;
}

.A_checkbox__box {
  display: grid;
  align-items: center;
  grid-template-columns: 2.4em auto;
  grid-column-gap: 0.3em;
  justify-content: center;
}

.A_checkbox__input {
  width: 2.4em;
  height: 2.4em;
}

///////
///
// Pouze pro Administrátory
///
//////

///////
///
//Volba šablony
///
//////

@template-scroll-time: 1.4s;

.A_theme__box {
  &:after {
    content: '';
    background-image: url('https://cdn.swbpg.com/o/i/theme-placeholder.svg');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.25em;
    display: block;
    padding-top: 75%;
  }
}

///
//Nastavení colorpicker v controlpanelu
///

.sketch-picker {
  background: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  margin: 0.75em auto !important;
  padding: 0 !important;
  width: 95% !important;
}

/*
---
Zmena barvy Edit
A_web-color BASIC
---
*/

.colorpicker.colorpicker-inline {
  position: initial !important;
}

///
//Animace načítání stránky
///

.A_admin__loading__cover {
  position: fixed;
  .inset-0;
  transition: all 0.3s;
  text-align: center;
  z-index: 5;

  &:before {
    content: '';
    position: absolute;
    .inset-0;
    background: var(--bg-white);
    opacity: 0.8;
    z-index: -1;
  }
}

///////
///
// Error používa se u loginu
///
///////

.A_login__messages {
  text-align: center;
  margin: 1em 0;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.A_login__message {
  .font-size(@font-size: 16, @line: 18);
  letter-spacing: 0.02em;
  font-family: @font1;
  font-weight: 400;
  border-radius: 4px;
  padding: 0.6em 0.4em;
  box-sizing: border-box;
}

.A_login__message--error {
  background-color: @color-error;
  box-shadow: 0 0 0 0 @color-variable-1, 0 10px 12.5px 0 rgba(0, 0, 0, 0.15);
  color: @color-main-2;
}

.A_login__message--warn {
  background-color: #ffffcc;
  box-shadow: 0 0 0 0 @color-variable-1, 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  color: @color-main-1;
  .font-size(@font-size: 14, @line: 17);
}

.A_login__message--info {
  background-color: #f2f2f2;
  box-shadow: 0 0 0 0 @color-variable-1, 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  color: @color-main-1;
}

///
//Redesign LOGIN
///

.A_overflow--fullscreen {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #044579;
  background-image: url(https://saywebpage.com/public/cs/img/fullscreen--2000.jpg);
  background-position: center bottom;
  min-height: 100vh;
}

/*
-----
Nové
-----
*/

.A_card {
  background: var(--bg-white);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 3px 12px -1px fade(@color-variable-1, 8%), 0 0px 0px 2px fade(@color-variable-1, 3%);
  position: relative;
}

.A_card__box {
  position: relative;
}

.A_card__box--padding {
  padding: 1.75em;

  @media screen and (min-width: 600px) {
    padding: 7.5% 10%;
  }
}

.A_card__box--padding-bigger {
  padding: 4em 2em;

  @media screen and (min-width: 600px) {
    padding: 12%;
  }
}

/*
-----
obecne
-----
*/

.A_mask-linear {
  &:after {
    content: '';
    display: block;
    background: linear-gradient(to top, var(--bg-white) 0, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }

  .A_mask-linear__content {
    &:after {
      z-index: -2;
    }
  }
}

.A_mask-linear__content {
  &:after {
    content: '';
    display: block;
    box-shadow: 0 3px 12px -1px rgba(var(--border-primary), 0.15),
      0 0 0 2px rgba(var(--border-primary), 0.04);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.A_mask-linear--primary {
  &:after {
    top: -2.5vw;
    left: -2.5vw;
    right: -2.5vw;
    bottom: -2.5vw;
  }

  .A_mask-linear__content {
    &:after {
      box-shadow: rgba(var(--border-primary), 0.35) 0 0 2vw 0;
    }
  }
}

/*
-----
Sign
-----
*/

.modal__wrap--slim {
  max-width: 500px !important;

  @media screen and (max-width: 660px), screen and (max-height: 600px) {
    max-width: none !important;
  }
}

.modal__wrap--blue {
  background: #f2f7fd !important;
}

//defaultni nastaveni scrollbaru
.custom-scrollbar {
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 0.75em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5em;
    border: 0.3em solid var(--bg-white);
    background: var(--text-dark-black);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.A_scrollbar {
  padding: 0.5em;
  box-sizing: border-box;

  @media screen and (min-width: @md-BP) {
    padding: 0.75em 0.75em 0 0.75em;
  }

  .custom-scrollbar;
}

/*
---
Strankovani
A_page BASIC
---
*/

@A_page--BP-1: 820px;

.A_page__wrap {
  display: block;
  max-width: 980px;
  margin: auto;
}

.A_card--page {
  padding: 1.75em 2em;

  @media screen and (min-width: @A_page--BP-1) {
    padding: 1.25em 2.25em;
  }
}

.A_card--page + .A_card--page {
  margin-top: 0.75em;
}

.A_page__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em;

  @media screen and (min-width: @A_page--BP-1) {
    grid-template-columns: 250px auto;
    align-items: center;
    justify-content: space-between;
  }
}

.A_page__row-setting {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: @A_page--BP-1) {
    justify-content: space-between;
  }
  @media screen and (max-width: @A_page--BP-1) {
    .A_page__row-point + .A_page__row-point {
      width: 100%;

      a,
      button {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: @xsm-BP) {
    display: block;
    .A_page__row-point + .A_page__row-point {
      margin-left: auto;
      margin-top: 1.25em;
    }
    a,
    button {
      width: 100%;
    }
  }
}

.A_page__row-point + .A_page__row-point {
  margin-left: 1.75em;
}

.A_page__title {
  @media screen and (min-width: @A_page--BP-1) {
    overflow: hidden;
    text-align: left;
  }
}

.A_page__btn {
  @media screen and (max-width: @A_page--BP-1) {
    button {
      width: 100%;
    }
  }
}

.A_card-disable {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: not-allowed;
  background-color: rgba(22, 30, 46, 0.45);
  border-radius: inherit;
  z-index: 2;
}

.A_card__panel {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: auto;
  background: var(--bg-white);
  padding: 4.5% 6.5%;
  display: grid;
  grid-gap: 1.25em;
  border-radius: 0 0 8px 8px;
  z-index: 3;

  @media screen and (min-width: @xsm-BP) {
    grid-template-columns: minmax(auto, 300px) auto;
    justify-content: space-between;
    align-items: center;
  }
}

.A_separate-line {
  width: 100%;
  height: 2px;
  background: #f5f8ff;
}

/*
---
Sablona T00 skryje nerelevantni text pro mobil
---
*/

.swp-T00-mobile {
  @media screen and (max-width: 750px) {
    display: none;
  }
}

//myOrder

@myOrder--BP-1: 840px;
@myOrder--BP-2: 660px;

.A_card__box--myOrder {
  padding: 5%;
}

.A_myOrder__flex {
  align-items: start;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em 10%;
  padding: 2em 1.5em;

  @media screen and (min-width: @myOrder--BP-2) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: @myOrder--BP-1) {
    align-items: center;
    grid-template-columns: minmax(85px, auto) minmax(85px, auto) auto 1fr;
    padding: 0.5em 2em;
  }
}

.A_myOrder__flex--mdes {
  @media screen and (max-width: @myOrder--BP-1) {
    display: none;
  }
}

.A_myOrder__box {
  border-radius: 6px;
  border: 2px solid rgba(108, 157, 217, 0.15);
  min-height: 65px;
}

@myOrder-waiting--BP-1: 1000px;
@myOrder-waiting--BP-2: 660px;

.A_myOrder__box--waiting {
  align-items: start !important;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em 1em;
  padding: 2em 1.5em;

  @media screen and (max-width: @myOrder-waiting--BP-2) {
    text-align: center;
  }

  @media screen and (min-width: @myOrder-waiting--BP-2) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: @myOrder-waiting--BP-1) {
    align-items: center;
    grid-template-columns: minmax(280px, auto) minmax(280px, auto) minmax(240px, auto);
    padding: 1.5em 2em;
  }
}

.A_myOrder__waiting-pay {
  @media screen and (min-width: @myOrder-waiting--BP-2) and (max-width: @myOrder-waiting--BP-1) {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
  }
  @media screen and (min-width: @myOrder-waiting--BP-1) {
    text-align: right;
  }
}

.A_myOrder__point {
  @media screen and (max-width: @myOrder--BP-1) {
    text-align: center;
  }
  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;

    @media screen and (max-width: @myOrder--BP-1) {
      margin-top: 0.25em;
    }

    @media screen and (min-width: @xsm-BP) {
      &:hover {
        .myOrderIcon {
          fill: @color-main-1;
        }
      }
    }
  }
}
.myOrderIcon {
  fill: @color-variable-1;
}

.A_myOrder__point--right {
  @media screen and (min-width: @myOrder--BP-1) {
    text-align: right;
  }
}

.A_myOrder__des {
  margin-bottom: 0.25em;

  @media screen and (min-width: @myOrder--BP-1) {
    display: none;
  }
}

.A_myOrder__box + .A_myOrder__box {
  margin-top: 2em;

  @media screen and (min-width: @myOrder--BP-2) {
    margin-top: 0.5em;
  }
}

.scale-box {
  font-size: 16px;
  font-size: clamp(16px, 1.15vw, 22px);
}

/*
///
///

THEMES

///
//
*/

@themes--BP-2: 1130px;
@themes--BP-3: 800px;

.A_sidebar__box {
  min-width: 10em;
}

.A_themes__wrap {
  align-items: start;
  display: grid;
  grid-column-gap: 2vw;
  grid-row-gap: 2vw;
  grid-template-columns: 1fr;
  flex: 1 1 100%;

  @media screen and (min-width: @themes--BP-3) {
    grid-template-columns: 1fr 1fr;
  }
  .desktop {
    display: none;
  }
  @media screen and (min-width: @themes--BP-2) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
}

.A_themes__wrapper {
  position: relative;
  box-sizing: border-box;
  max-width: 540px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: @themes--BP-2) {
    border-radius: 0.4vw;
    transition: all 0.5s ease;
    overflow: hidden;
    max-width: none;

    &:before {
      content: '';
      display: block;
      padding-bottom: 72.5%;
    }

    &:hover {
      .A_themes__box {
        transform: translateY(-5%);
      }
      .A_themes__content {
        opacity: 1;
      }
    }
  }
}

.A_themes__cover {
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  background: #445968;

  &:before {
    content: '';
    display: block;
    padding-bottom: 82%;
  }

  @media screen and (min-width: @themes--BP-2) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.A_themes__box {
  position: absolute;
  width: 80%;
  top: 10%;
  left: ((100%-80%)/2);
  right: 0;
  bottom: 0;
  background-image: url(https://cdn.swbpg.com/o/i/theme-placeholder.svg);
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: @themes--BP-2) {
    width: 70%;
    top: 13%;
    left: ((100%-70%)/2);
    transition: transform 670ms cubic-bezier(0.21, 0.78, 0, 1);
    border-radius: 2px;
    -webkit-mask-image: -webkit-linear-gradient(
      -90deg,
      rgba(0, 0, 0, 1) 76%,
      rgba(0, 0, 0, 0.9) 81%,
      rgba(0, 0, 0, 0.2) 98%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }
}

.A_themes__content {
  box-sizing: border-box;
  padding: 2.25vmax 0 1.75vmax;

  @media screen and (min-width: @themes--BP-2) {
    padding: 10% 8% 7% 8%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: transform 750ms cubic-bezier(0.21, 0.78, 0, 1), opacity 200ms ease-out;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.6) 75%,
      rgba(0, 0, 0, 0.7) 100%
    );
    opacity: 0;

    .text-gray-600,
    .m-text {
      color: var(--bg-white) !important;
    }
    .d-text {
      display: block;
      line-height: 1.4;
    }
  }
  @media screen and (max-width: @themes--BP-2) {
    .m-text {
      color: var(--text-black);
      font-size: 125%;
      font-weight: 500;
      display: block;
    }
  }
}

.ratio-ogg {
  &:after {
    content: '';
    display: block;
    padding-top: 52%;
  }
}

.bg-gradient--custom {
  background: linear-gradient(
    to top,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );
}

.A_grid--myproject {
  grid-template-columns: 100%;

  @media screen and (min-width: @xsm-BP) {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}

.A_grid--layout1 {
  grid-gap: 3vmax 10%;
  grid-template-columns: 1fr;

  @media screen and (min-width: @sm-BP) {
    grid-template-columns: minmax(120px, 1fr) minmax(auto, 2fr);
  }
}

.lg\:A_grid--section {
  @media screen and (min-width: @lg-BP) {
    grid-template-columns: minmax(220px, 1fr) minmax(auto, 3.5fr) !important;
  }
}

.A_grid--upload {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.A_more {
  transition: all 100ms linear;

  &:hover {
    @media screen and (min-width: @xsm-BP) {
      circle {
        animation: jump 400ms ease;

        &:nth-child(2) {
          animation-delay: 100ms;
        }
        &:nth-child(3) {
          animation-delay: 200ms;
        }
      }
    }
  }
  &:active {
    transform: scale(0.9);
  }
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  10% {
    transform: translateY(0px);
  }
}

.gallery-bg {
  background: rgba(var(--border-primary), 0.5);
  transition: 200ms background ease-out;
}

.gallery-bg--delete {
  &:hover {
    ~ .gallery-bg {
      background: rgba(228, 62, 62, 0.5);
    }
  }
}

@import 'defaultOptions.less';

@import 'component.table.less';
@import 'component.tablor.less';
@import 'component.upload.less';
@import 'utilities.less';

@import '../../node_modules/react-image-crop/dist/ReactCrop.css';
@import 'component.rc-slider.less';

@import 'dark.less';
