.gap-1 {
  gap: 0.25em;
}

.gap-2 {
  gap: 0.5em;
}

.gap-3 {
  gap: 0.75em;
}

.gap-4 {
  gap: 1em;
}

.gap-5 {
  gap: 1.25em;
}

.gap-6 {
  gap: 1.5em;
}

.gap-8  {
  gap: 2em;
}

.gap-10  {
  gap: 2.5em;
}

.gap-12  {
  gap: 3em;
}

.gap-x-3 {
  gap: 0.75em;
}

.gap-x-5 {
  gap: 1.25em;
}

.gap-x-8 {
  column-gap: 2em;
}

.gap-y-5 {
  row-gap: 1.25em;
}

@media screen and (min-width: @xsm-BP) {
  .xsm\:gap-y-2 {
    row-gap: 0.5em;
  }
}

@media screen and (min-width: @md-BP) {
  .md\:gap-3 {
    gap: 0.75em;
  }
}

@media screen and (min-width: @md-BP) {
  .md\:gap-5 {
    gap: 1.25em;
  }

  .md\:gap-16  {
    gap: 4em;
  }

  .md\:gap-2 {
    gap: 0.5em !important;
  }
}
