.breadcrumbs {
  transform: translateX(0);

  .parent {
    opacity: 0;
    transition: transform 640ms cubic-bezier(0.19, 1, 0.22, 1), opacity 150ms ease-in-out,
      color 100ms ease-in-out;
    transform: translateX(-50px);
  }

  .child {
    transition: transform 640ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    .parent {
      opacity: 1;
      transform: translateX(0);
    }

    .child {
      transform: translateX(0) !important;
    }
  }
}
