:root {
  --bg-main: rgba(240, 244, 254, 1);
  --bg-white: rgba(255, 255, 255, 1);
  --bg-global: rgba(98, 85, 186, 1);
  --bg-primary: rgb(0, 96, 217);
  --bg-extra: rgb(170, 42, 162);
  --bg-indigo: rgb(23, 40, 77);
  --bg-light-blue: rgb(236, 243, 254);
  --bg-light-gray: rgb(242, 246, 255);
  --bg-red: rgb(220, 38, 38);

  --text-white: #fff;
  --text-black: #1a2241;
  --text-primary: rgb(0, 96, 217);
  --text-dark-black: rgb(78, 94, 142);
  --border-primary: 0, 96, 217;
  --border-global: 98, 85, 186;
  --border-white: 255, 255, 255;
  --border-gray-200: 242, 246, 255;
  --text-btn-secondary: var(--text-primary);
  --bg-btn-secondary: var(--text-primary);
}

.darkMode() {
  --bg-main: rgb(24, 24, 26);
  --bg-white: var(--color-dark-gray-darker);
  --bg-light-blue: rgba(47, 50, 52, 1);

  --bg-light-gray: rgba(90, 90, 90, 1);
  --bg-primary: rgb(40, 110, 230);

  --bg-light-gray-dark: rgba(72, 80, 92, 1);
  --bg-gray-dark: rgba(52, 52, 52, 1);

  --text-black: #fff;
  --text-dark-black: #a4aaad;
  --text-primary: rgb(50, 120, 250);
  --text-gray-light: rgb(164, 170, 173);
  --text-gray-lighter: rgb(210, 210, 210);

  --border-gray-200: 68, 68, 68; // --color-dark-gray-lighter

  --color-dark-gray-darkest: rgb(31, 32, 35);
  --color-dark-gray-darker: rgba(41, 42, 45, 1);
  --color-dark-gray-dark: rgba(52, 52, 52, 1);
  --color-dark-gray-light: rgba(62, 63, 66, 1);
  --color-dark-gray-lighter: rgba(68, 68, 68, 1);
  --color-dark-gray-lightest: rgba(88, 88, 88, 1);

  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-inset: rgba(255, 255, 255, 0.05);

  color: white;

  .bg-gradient--custom {
    background: linear-gradient(
      to top,
      hsl(225, 6%, 13%) 0%,
      hsla(225, 6%, 13%, 0.987) 8.1%,
      hsla(225, 6%, 13%, 0.951) 15.5%,
      hsla(225, 6%, 13%, 0.896) 22.5%,
      hsla(225, 6%, 13%, 0.825) 29%,
      hsla(225, 6%, 13%, 0.741) 35.3%,
      hsla(225, 6%, 13%, 0.648) 41.2%,
      hsla(225, 6%, 13%, 0.55) 47.1%,
      hsla(225, 6%, 13%, 0.45) 52.9%,
      hsla(225, 6%, 13%, 0.352) 58.8%,
      hsla(225, 6%, 13%, 0.259) 64.7%,
      hsla(225, 6%, 13%, 0.175) 71%,
      hsla(225, 6%, 13%, 0.104) 77.5%,
      hsla(225, 6%, 13%, 0.049) 84.5%,
      hsla(225, 6%, 13%, 0.013) 91.9%,
      hsla(225, 6%, 13%, 0) 100%
    );
  }

  .dark\:bg-light-gray-dark {
    background: var(--bg-light-gray-dark) !important;
  }

  .dark\:bg-gray-dark {
    background: var(--bg-gray-dark) !important;
  }

  .dark\:bg-light-gray {
    background: var(--bg-light-gray) !important;
  }

  .dark\:hover\:bg-light-gray {
    &:hover {
      background: var(--bg-light-gray) !important;
    }
  }

  ///
  .dark\:bg-dark-gray-darkest {
    background: var(--color-dark-gray-darkest) !important;
  }

  .dark\:bg-dark-gray-darker {
    background: var(--color-dark-gray-darker) !important;
  }

  .dark\:bg-dark-gray-dark {
    background: var(--color-dark-gray-dark) !important;
  }

  .dark\:bg-dark-gray-light {
    background: var(--color-dark-gray-light) !important;
  }

  .dark\:hover\:bg-dark-gray-light {
    &:hover {
      background: var(--color-dark-gray-light) !important;
    }
  }

  .dark\:bg-dark-gray-lighter {
    background: var(--color-dark-gray-lighter) !important;
  }

  .dark\:bg-dark-gray-lightest {
    background: var(--color-dark-gray-lightest) !important;
  }

  .dark\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .dark\:border-gray-200 {
    border-color: rgba(var(--border-gray-200), var(--tw-border-opacity));
  }

  .dark\:border-primary {
    border-color: rgba(var(--border-primary), var(--tw-border-opacity));
  }

  .dark\:border-white {
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .dark\:text-white {
    color: var(--text-white) !important;
  }

  .dark\:text-dark {
    color: var(--bg-main) !important;
  }

  .dark\:hover\:text-white {
    &:hover {
      color: var(--text-white) !important;
    }
  }

  //
  .dark\:text-gray-lightest {
    color: var(--text-gray-lighter) !important;
  }

  .dark\:text-gray-light {
    color: var(--text-gray-light) !important;
  }

  .dark\:shadow-none {
    box-shadow: none !important;
  }

  .dark\:hover\:shadow-none {
    &:hover {
      box-shadow: none !important;
    }
  }

  .dark\:after\:shadow-none {
    &:after {
      box-shadow: none !important;
    }
  }

  .dark\:after\:background-none {
    &:after {
      background: none !important;
    }
  }

  .dark\:hover\:brightness-125 {
    &:hover {
      filter: brightness(1.25);
    }
  }

  .dark\:focus\:secondary-btn {
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba(var(--border-primary), 1);
    }
  }

  .dark\:shadow-1 {
    box-shadow: 0 12px 28px 0 var(--shadow-2), 0 2px 4px 0 var(--shadow-1),
      inset 0 0 0 1px var(--shadow-inset) !important;
  }

  .dark\:hidden {
    display: none !important;
  }

  .A_themes__content {
    .dark\:text-white {
      color: var(--text-white) !important;
    }
  }
  //chybi min-with
  [data-reach-listbox-popover] {
    .dark\:shadow-1;
  }

  [data-reach-listbox-option],
  [data-reach-menu-item],
  [data-reach-dialog-list-option] {
    .dark\:text-white;
    &:hover,
    &:focus,
    &[data-current-selected],
    &[data-current-nav] {
      .dark\:bg-dark-gray-light;
      .dark\:text-white;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 2px solid var(--bg-primary);
    -webkit-text-fill-color: var(--text-primary);
    -webkit-box-shadow: 0 0 0px 1000px var(--color-dark-gray-lightest) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

@media (prefers-color-scheme: dark) {
  html:not(.light):not(.dark) {
    color-scheme: dark;

    .darkMode();
  }
}

html.dark {
  color-scheme: dark;

  .darkMode();
}

html.light {
  color-scheme: light;
}

html:not(.light):not(.dark) {
  color-scheme: light dark;
}
