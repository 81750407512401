.box {
  color: var(--text-black);
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 1.05;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
