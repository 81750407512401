/**
 * FONT PX->REM + lineheight MIXINS
 *
 */

.font-size(@font-size: @baseFontSize, @line: @baseLineHeight) {
  @rem: (@font-size / @baseFontSize);
  @remline: (@line / @font-size);
  font-size: @font-size * 1px;
  font-size: ~'@{rem}rem';
  line-height: ~'@{remline}';
}
