.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-4 {
  bottom: 1em;
}

.left-0 {
  left: 0;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.top-2 {
  top: 0.5em;
}

.top-3 {
  top: 0.75em;
}

.top-5 {
  top: 1.25em;
}

.top-18 {
  top: 4.5em;
}

.top-1\/2 {
  top: 50%;
}

.top-60px {
  top: 60px;
}

.\-left-2 {
  left: -0.5em;
}

.left-4 {
  left: 1em;
}

.left-5 {
  left: 1.25em;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: 0.5em;
}

.right-3 {
  right: 0.75em;
}

.right-4 {
  right: 1em;
}

@media screen and (min-width: @md-BP) {
  .md\:bottom-auto {
    bottom: auto;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-13 {
    top: 3.25em;
  }

  .md\:fixed {
    position: fixed !important;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:top-0 {
    top: 0;
  }

  .lg\:sticky {
    position: sticky;
  }
}
