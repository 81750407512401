.snackbar {
  align-items: flex-start;
  bottom: 2em;
  display: flex;
  flex-direction: column-reverse;
  left: 2em;
  position: fixed;
  right: 2em;
  z-index: 11;
}

.snackbar__snack {
  .sh-a;
  border-radius: 8px;
  background: #1a2241;
  color: var(--bg-light-gray);
  font-size: 0.875em;
  margin-top: 1em;
  padding: 1em;
}

.snackbar__snack--success {
  border-left: 8px solid @color-variable-1;
}

.snackbar__snack--error {
  border-left: 8px solid var(--bg-red);
}

.snackbar__snack-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.snackbar__snack-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in, transform 500ms ease-out;
}
.snackbar__snack-exit {
  opacity: 1;
  transform: translateX(0);
}
.snackbar__snack-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms ease-out, transform 500ms ease-in;
}
