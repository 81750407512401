.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-self-end {
  justify-self: end;
}

.justify-items-center {
  justify-items: center;
}

@media screen and (min-width: @md-BP) {
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:justify-start {
    justify-content: flex-start !important;
  }
}
