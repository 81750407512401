.\-rotate-90 {
  --tw-rotate: -90deg !important;
}

.rotate-90 {
  --tw-rotate: 90deg !important;
}

.rotate-180 {
  --tw-rotate: 180deg !important;
}
