.min-h-screen {
  min-height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.min-h-50 {
  min-height: 50vh;
}

.min-h-10 {
  min-height: 2.5em;
}

.min-h-13 {
  min-height: 3.25em;
}

.lg\:min-h-60 {
  @media screen and (min-width: @lg-BP) {
    min-height: 60vh !important;
  }
}
