.c-switcher {
  align-items: center;
  display: grid;
  gap: 0.5em;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.c-switcher__input {
  display: inline-block;
  flex: 0 0 40px;
  height: 1.5rem;
  position: relative;
  width: 40px;
  margin-bottom: 0 !important;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .c-switcher__slider {
    background-color: var(--text-primary);

    &:before {
      background-color: #fff;
      transform: translateX(1rem);
    }
  }

  input[disabled]:checked + .c-switcher__slider {
    background-color: #3390d9;
    cursor: not-allowed;
  }
}

.c-switcher__input--smaller {
  flex: 0 0 38px;
  height: 22px;
  width: 38px;

  input:checked + .c-switcher__slider {
    &:before {
      transform: translateX(16px);
    }
  }
}

.c-switcher__slider {
  background-color: rgba(var(--border-primary), 0.1);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &:before {
    background-color: #fff;
    bottom: 0.25rem;
    content: '';
    height: 1rem;
    left: 0.25rem;
    position: absolute;
    transition: 0.4s;
    width: 1rem;
  }
}

.c-switcher__slider--smaller {
  &:before {
    bottom: 3px;
    height: 16px;
    left: 3px;
    width: 16px;
  }
}

.c-switcher__slider--round {
  border-radius: 1rem;

  &:before {
    border-radius: 50%;
  }
}
