.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-8 {
  margin-left: 2em;
  margin-right: 2em;
}

.my-1 {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.my-2 {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.my-3 {
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}

.my-4 {
  margin-bottom: 1em;
  margin-top: 1em;
}

.my-6 {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.my-8 {
  margin-bottom: 2em;
  margin-top: 2em;
}

.my-10 {
  margin-bottom: 2.5em;
  margin-top: 2.5em;
}

.\-mx-3 {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.\-mx-4 {
  margin-left: -1em;
  margin-right: -1em;
}

.\-mx-5 {
  margin-left: -1.25em;
  margin-right: -1.25em;
}

.\-mx-6 {
  margin-left: -1.5em;
  margin-right: -1.5em;
}

.\-mx-8 {
  margin-left: -2em;
  margin-right: -2em;
}

.\-mx-10 {
  margin-left: -2.5em;
  margin-right: -2.5em;
}

.\-mr-3 {
  margin-right: -0.75em;
}

.\-my-1 {
  margin-top: -0.25em;
  margin-bottom: -0.25em;
}

.\-mb-1 {
  margin-bottom: -0.25em;
}

.\-m-1 {
  margin: -0.25em;
}

.\-m-2 {
  margin: -0.5em;
}

.\-m-3 {
  margin: -0.75em;
}

.\-m-4 {
  margin: -1em;
}

.m-0 {
  margin: 0em !important;
}

.m-1 {
  margin: 0.25em;
}

.m-2 {
  margin: 0.5em;
}

.m-4 {
  margin: 1em;
}

.m-5 {
  margin: 1.25em;
}

.m-6 {
  margin: 1.5em;
}

.m-8 {
  margin: 2rem;
}

.mx-1 {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.mx-2 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.mx-4 {
  margin-left: 1em;
  margin-right: 1em;
}

.mx-6 {
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25em;
}

.mb-2 {
  margin-bottom: 0.5em !important;
}

.mb-3 {
  margin-bottom: 0.75em;
}

.mb-4 {
  margin-bottom: 1.25em;
}

.mb-5 {
  margin-bottom: 1.25em;
}

.mb-6 {
  margin-bottom: 1.5em;
}

.sm\:mb-3 {
  @media screen and (min-width: @sm-BP) {
    margin-bottom: 0.75em;
  }
}

.sm\:mb-6 {
  @media screen and (min-width: @sm-BP) {
    margin-bottom: 1.5em;
  }
}

.mb-8 {
  margin-bottom: 2em;
}

.mb-10 {
  margin-bottom: 2.5em;
}

.sm\:mb-10 {
  @media screen and (min-width: @sm-BP) {
    margin-bottom: 2.5em;
  }
}

.mb-12 {
  margin-bottom: 3em;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25em;
}

.mt-2 {
  margin-top: 0.5em;
}

.mt-3 {
  margin-top: 0.75em;
}

.mt-4 {
  margin-top: 1em;
}

.mt-8 {
  margin-top: 2em;
}

.mt-10 {
  margin-top: 2.5em !important;
}

.mr-1 {
  margin-right: 0.25em;
}

.mr-2 {
  margin-right: 0.5em;
}

.mr-3 {
  margin-right: 0.75em;
}

.mr-4 {
  margin-right: 1em;
}

.mr-5 {
  margin-right: 1.25em;
}

.mr-7 {
  margin-right: 1.75em;
}

.mr-8 {
  margin-right: 2em;
}

.mr-12 {
  margin-right: 3em;
}

.mr-auto {
  margin-right: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-2 {
  margin-left: 0.5em;
}

.ml-3 {
  margin-left: 0.75em;
}

.ml-4 {
  margin-left: 1em;
}

.ml-6 {
  margin-left: 1.5em;
}

.\-ml-3 {
  margin-left: -0.75em;
}

.\-ml-5 {
  margin-left: -1.25em;
}

.\-ml-6 {
  margin-left: -1.5em !important;
}

.\-ml-10x {
  margin-left: -2.6em !important;
}

.\-mr-5 {
  margin-right: -1.25em;
}

.\-mr-6 {
  margin-right: -1.5em !important;
}

@media screen and (min-width: @sm-BP) {
  .sm\:-mx-5 {
    margin-left: -1.25em;
    margin-right: -1.25em;
  }

  .sm\:-mx-10 {
    margin-left: -2.5em;
    margin-right: -2.5em;
  }

  .sm\:-mt-10 {
    margin-top: -2.5em;
  }
}

.xsm\:mb-0 {
  @media screen and (min-width: @xsm-BP) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: @md-BP) {
  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:-mx-3 {
    margin-left: -0.75em;
    margin-right: -0.75em;
  }
}

.\-mb-2p {
  margin-bottom: -2px;
}

@media screen and (min-width: @md-BP) {
  .md\:m-0 {
    margin: 0;
  }
  .md\:mb-3 {
    margin-bottom: 0.75em;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:-mt-10 {
    margin-top: -2.5em;
  }

  .\-ml-5 {
    margin-left: -1.25em;
  }
}
