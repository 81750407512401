.c-tooltip  {
  .bg-indigo;
  .sh-a;
  .text-12-14;
  .font-sans;
  .rounded-lg;
  letter-spacing: 0.025em;
  color: white;
  line-height: normal;
  max-width: 500px;
  opacity: 0;
  padding: 0.5em 1em;
  text-align: center;
  transform: translate(0, 0);

  p {
    margin: 1em 0;
  }
}

[data-popper-placement='left'] > .c-tooltip {
  animation: tooltipLeft 0.25s ease 0.5s forwards, tooltipOpacity 0.1s linear 0.5s forwards;
}

[data-popper-placement='right'] > .c-tooltip {
  animation: tooltipRight 0.25s ease 0.5s forwards, tooltipOpacity 0.1s linear 0.5s forwards;
}

[data-popper-placement='top'] > .c-tooltip {
  animation: tooltipTop 0.25s ease 0.5s forwards, tooltipOpacity 0.1s linear 0.5s forwards;
}

[data-popper-placement='bottom'] > .c-tooltip {
  animation: tooltipBottom 0.25s ease 0.5s forwards, tooltipOpacity 0.1s linear 0.5s forwards;
}

.c-tooltip.c-tooltip--main  {
  .text-14-15;
  line-height: 1.25;
  max-width: 280px;
  padding: 1em;
  transform: translateY(10px);
  animation: tooltipMain 0.5s ease 0.5s forwards, tooltipOpacity 0.1s linear 0.5s forwards;

  p {
    margin: 0.5em 0;
  }
}

@keyframes tooltipLeft {
  0% {
    transform: translateX(-15%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes tooltipRight {
  0% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes tooltipTop {
  0% {
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes tooltipBottom {
  0% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes tooltipMain {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes tooltipOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
