.space-y-1 > * + * {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
}

.space-y-2 > * + * {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
}

.space-y-4 > * + * {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
}

.space-y-8 > * + * {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
}

.space-y-9 > * + * {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
}

.space-x-2 > * + * {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > * + * {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > * + * {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > * + * {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > * + * {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
