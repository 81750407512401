.generate-columns(@n, @prefix, @i: 1) when (@i =< @n) {
  .@{prefix}grid-cols-@{i} {
    grid-template-columns: repeat(@i, 1fr);
  }
  .generate-columns(@n, @prefix, (@i + 1));
}

.generate-columns(12, ~'');

.grid-cols-2-auto {
  grid-template-columns: auto auto;
}

@media screen and (min-width: @lg-BP) {
  .lg\:grid-cols-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: @xsm-BP) {
  .xsm\:grid-cols-2a {
    grid-template-columns: auto auto;
  }
}

@media screen and (min-width: @md-BP) {
  .generate-columns(12, md\:);

  .md\:grid-cols-2-auto {
    grid-template-columns: auto auto;
  }
}
