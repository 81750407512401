.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-6 {
  border-width: 6px;
}

@media screen and (min-width: @lg-BP) {
  .lg\:border-4 {
    border-width: 4px;
  }
}

.border-b-1 {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-1 {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(var(--border-white), var(--tw-border-opacity));
}

.border-blue-primary {
  --tw-border-opacity: 1;
  border-color: rgba(108, 157, 217, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(var(--border-gray-200), var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(var(--border-primary), var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgba(228, 62, 62, var(--tw-border-opacity));
}

.border-orange {
  --tw-border-opacity: 1;
  color: rgba(211, 145, 74, var(--tw-border-opacity));
}

.border-yellow {
  --tw-border-opacity: 1;
  color: rgba(168, 168, 0, var(--tw-border-opacity));
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgba(16, 204, 16, var(--tw-border-opacity));
}

.border-opacity-0 {
  --tw-border-opacity: 0;
}

.border-opacity-8 {
  --tw-border-opacity: 0.08;
}

.border-opacity-15 {
  --tw-border-opacity: 0.15;
}

.border-opacity-100 {
  --tw-border-opacity: 1;
}

.rounded-inherit {
  border-radius: inherit;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: 0.125em;
}

.rounded {
  border-radius: 0.25em;
}

.md\:rounded {
  @media screen and (min-width: @md-BP) {
    border-radius: 0.25em !important;
  }
}

.rounded-md {
  border-radius: 0.375em;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
}

.after\:rounded-md {
  &:after {
    border-radius: 0.375em;
  }
}

.rounded-lg {
  border-radius: 0.5em;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-sm {
  border-top-left-radius: 0.125em;
  border-bottom-left-radius: 0.125em;
}

.rounded-r-sm {
  border-top-right-radius: 0.125em;
  border-bottom-right-radius: 0.125em;
}

.sm\:rounded-md {
  @media screen and (min-width: @sm-BP) {
    border-radius: 0.375em;
  }
}

.sm\:rounded {
  @media screen and (min-width: @sm-BP) {
    border-radius: 0.25em;
  }
}

.lg\:rounded {
  @media screen and (min-width: @lg-BP) {
    border-radius: 0.25em;
  }
}

.lg\:rounded-t {
  @media screen and (min-width: @lg-BP) {
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
  }
}
