.h-1 {
  height: 0.25em;
}

.h-2 {
  height: 0.5em;
}

.h-3 {
  height: 0.75em;
}

.h-4 {
  height: 1em;
}

.h-5 {
  height: 1.25em;
}

.h-6 {
  height: 1.5em;
}

.h-7 {
  height: 1.75em;
}

.h-8 {
  height: 2em;
}

.h-14 {
  height: 3.5em;
}

.h-18 {
  height: 4.5em;
}

.h-28 {
  height: 7em;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-inherit {
  height: inherit;
}

@media screen and (min-width: @lg-BP) {
  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-4 {
    height: 1em;
  }

  .lg\:h-screen {
    height: 100vh;
  }
}
