.min-w-0 {
  min-width: 0;
}

.min-w-300p {
  min-width: 300px;
}

.md\:min-w-450p {
  @media screen and (min-width: @md-BP) {
    min-width: 450px !important;
  }
}
