.w-auto {
  width: auto !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw;
}

.w-1\/2 {
  width: 50%;
}

.w-2 {
  width: 0.5em !important;
}

.w-3 {
  width: 0.75em !important;
}

.w-4 {
  width: 1em !important;
}

.w-5 {
  width: 1.25em;
}

.w-6 {
  width: 1.5em;
}

.w-7 {
  width: 1.75em;
}

.w-8 {
  width: 2em;
}

.w-20 {
  width: 5em;
}

.w-60 {
  width: 15em;
}

.w-max-content {
  width: max-content;
}

@media screen and (min-width: @md-BP) {
  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-full {
    width: 100% !important;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:w-4 {
    width: 1em;
  }
}

@media screen and (min-width: @sm-BP) {
  .sm\:w-auto {
    width: auto !important;
  }
}

@media screen and (min-width: @xsm-BP) {
  .xsm\:w-auto {
    width: auto !important;
  }
}
