.bg-white {
  background-color: var(--bg-white);
}

.bg-global {
  background-color: var(--bg-global);
}

.bg-none {
  background: none;
}

.bg-primary {
  background-color: var(--bg-primary);
}

.bg-extra {
  background-color: var(--bg-extra);
}

.bg-indigo {
  background-color: var(--bg-indigo);
}

.bg-light-blue {
  background-color: var(--bg-light-blue);
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg-overlay {
  background-color: rgba(23, 28, 50, 0.6);
}

.hover\:bg-light-blue {
  @media screen and (min-width: 480px) {
    &:hover {
      background-color: var(--bg-light-blue);
    }
  }
}

.hover\:bg-blue {
  @media screen and (min-width: 480px) {
    &:hover {
      background-color: #d1e2fc;
    }
  }
}

.bg-gray-200 {
  background-color: var(--bg-light-gray);
}

.bg-red-600 {
  background-color: var(--bg-red);
}

.bg-main {
  background: var(--bg-main);
}

.bg-center {
  background-position: center;
}

.bg-cover {
  background-size: cover;
}

.bg-danger {
  background-color: rgba(228, 62, 62, 0.08);
}

.bg-warning {
  background-color: rgba(211, 145, 74, 0.08);
}
