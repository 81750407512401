.dialogOverlay[data-reach-dialog-overlay] {
  background: rgba(23, 28, 50, 0.6);
}

.dialogContent[data-reach-dialog-content] {
  animation: modal-open--scale 640ms cubic-bezier(0.19, 1, 0.22, 1),
    modal-open--opacity 150ms ease-in-out forwards;
  margin: 0;
  opacity: 0;
  padding: 0;
}

@keyframes modal-open--scale {
  from {
    transform: translateY(25px);
  }
  to {
    ransform: translateY(0px);
  }
}

@keyframes modal-open--opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
