.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}
