.no-underline {
  text-decoration: none !important;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}
