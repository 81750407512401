.nestyd {
  display: flex;
  overflow: hidden;
  padding-bottom: 0.5em;

  & > * {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    max-width: 100%;
  }
}

.nestyd-enter:first-child {
  transform: translateX(-100%);
}
.nestyd-enter-active:first-child {
  transform: translateX(0%);
  transition: transform 640ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.nestyd-exit:first-child {
  transform: translateX(0%);
}
.nestyd-exit-active:first-child {
  transform: translateX(-100%);
  transition: transform 640ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.nestyd-enter:last-child {
  transform: translateX(0%);
}
.nestyd-enter-active:last-child {
  transform: translateX(-100%);
  transition: transform 640ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.nestyd-exit:last-child {
  transform: translateX(-100%);
}
.nestyd-exit-active:last-child {
  transform: translateX(0%);
  transition: transform 640ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.nestyd-exit-done:last-child {
  transform: translateX(0%);
}
