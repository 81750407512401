.A_link-text {
  border-bottom: 0.125em solid currentColor;
  color: var(--text-primary);
  cursor: pointer;
  display: inline;
  font-size: inherit;
  font-weight: 400;
  letter-spacing: -0.015em;
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: currentColor;
    opacity: 0;
    transition: opacity 120ms linear;
  }

  &:active {
    &:after {
      opacity: 0.2;
    }
  }

  @media screen and (min-width: 480px) {
    &:hover {
      &:after {
        opacity: 0.08;
      }
    }
  }
}

.A_link-text--secondary {
  border-bottom: 0.1em solid currentColor;
  color: rgba(78, 94, 142, 1);
  font-weight: 500;
}
