.box {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox {
  width: 1.25em;
  height: 1.25em;
  background: rgba(var(--border-primary), 0.08);
  border-radius: 2px;
  position: relative;

  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    right: 0.25em;
    bottom: 0.25em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Cpath fill='%23FFF' d='M29.24 74.55A8.16 8.16 0 0123.31 72L2.24 49.74a8.17 8.17 0 1111.87-11.23l14.65 15.47L65.47 8.49a8.18 8.18 0 0112.72 10.26L35.6 71.51a8.17 8.17 0 01-6.04 3.03l-.32.01z'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.checked,
  input:checked + & {
    background: var(--text-primary);

    &:after {
      display: block;
    }
  }
}

.isTouched {
}
