.opanelcity-appear,
.opanelcity-enter {
  opacity: 0;
}
.opanelcity-exit {
  opacity: 0;
}
.opanelcity-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
.opanelcity-appear-active,
.opanelcity-appear-done {
  opacity: 1;
  transition: opacity 300ms ease-out;
}
