.text-gray-400 {
  color: #9ca3af;
}

.text-gray-600 {
  color: #4b5563 !important;
}

.text-white {
  color: var(--text-white) !important;
}

.text-red-600 {
  color: var(--bg-red) !important;
}

.text-primary {
  color: var(--text-primary) !important;
}

.text-dark-black {
  color: var(--text-dark-black) !important;
}

.text-black {
  color: var(--text-black) !important;
}

.text-light-blue {
  color: #c4d3fd !important;
}

.text-dark-blue {
  color: #3e5281;
}

.text-light-error {
  color: @color-light-error !important;
}

.text-orange {
  color: #d3914a;
}

.text-yellow {
  color: rgb(168, 168, 0);
}

.text-green {
  color: rgb(16, 204, 16);
}

.hover\:text-primary {
  &:hover {
    color: var(--text-primary) !important;
  }
}

.hover\:text-primary-light {
  &:hover {
    color: var(--text-primary) !important;
  }
}
