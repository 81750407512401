.block {
  display: block !important;
}

.hidden {
  display: none;
}

.flex {
  display: flex !important;
}

.grid {
  display: grid !important;
}

.inline-block {
  display: inline-block;
}

@media screen and (min-width: @xsm-BP) {
  .xsm\:inline-block {
    display: inline-block !important;
  }

  .xsm\:flex {
    display: flex !important;
  }
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: @sm-BP) {
  .sm\:hidden {
    display: none !important;
  }

  .sm\:block {
    display: block !important;
  }

  .sm\:flex {
    display: flex !important;
  }
}

@media screen and (min-width: @md-BP) {
  .md\:flex {
    display: flex !important;
  }
  .md\:grid {
    display: grid !important;
  }
  .md\:block {
    display: block !important;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:flex {
    display: flex !important;
  }
}
