.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-9 {
  z-index: 9;
}

.z-10 {
  z-index: 10;
}

.z-10k {
  z-index: 10000;
}

.\-z-1 {
  z-index: -1;
}

.sm\:z-5 {
  @media screen and (min-width: @sm-BP) {
    z-index: 5;
  }
}
