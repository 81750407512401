/*
---
UI Admin SWP
---
*/

// for handling empty text content
[contenteditable] {
  display: inline-block;
  min-width: 1px; // display cursor in empty container

  &:empty:not(:focus) {
    cursor: text;

    &::before {
      content: '✍️…';
      display: inline-block;
      font-size: 1em;
      opacity: 0.5;
      text-align: center;
    }
  }
}

.user-page {
  color: var(--text-color);
}

// forces absolutely positioned sections to be relative or sticky
.admin {
  position: relative;

  --base-label-background: var(--text-primary);
  --base-label-shadow: var(--border-primary);

  .pos--fix {
    position: relative;
    top: auto;
    left: auto;
    z-index: auto;
  }

  .admin__module--global {
    --base-label-background: var(--bg-global);
    --base-label-shadow: var(--border-global);
  }

  .admin__module--site-header {
    overflow-y: visible !important;
    position: absolute !important;
    z-index: 2 !important;
  }

  ::selection {
    color: inherit;
    background-color: rgba(0, 60, 140, 0.4);
  }
}

[data-rbd-placeholder-context-id] {
  width: auto !important;
}

section.admin__module {
  border-radius: 0 !important;

  &:hover,
  &.drag-helper,
  &.--actionable {
    border-radius: 0 !important;
  }
}

// sections highlights and shadows
.admin__module {
  position: relative;

  @media screen and (min-width: @xsm-BP) {
    transition:
      background-color 0.25s ease-in-out,
      box-shadow 0.25s ease-in-out,
      border-radius 0.25s ease-in-out;
    box-shadow:
      rgba(var(--border-primary), 0) 0 0 0px 0px,
      inset rgba(var(--border-primary), 0) 0 0 0px 0px;
  }

  &.--highlightable,
  &.drag-helper {
    box-shadow:
      rgba(71, 82, 95, 0.3) 0 0 0px 1px,
      inset rgba(var(--border-primary), 0.3) 0 0 0px 1px;
    border-radius: 5px;
  }

  &.drag-helper {
    transition: none;
  }

  &.--highlighted {
    box-shadow:
      rgba(var(--border-primary), 0.3) 0 0 0px 1px,
      inset rgba(var(--border-primary), 0.3) 0 0 0px 1px;
    border-radius: 5px;
  }

  &.--clearable {
    min-height: 1em;
  }

  &.--actionable {
    box-shadow:
      rgba(var(--border-primary), 1) 0 0 0px 1px,
      inset rgba(var(--border-primary), 1) 0 0 0px 1px;
    border-radius: 5px;

    &.--shadow2 {
      box-shadow:
        rgba(var(--border-primary), 1) 0 0 0px 1px,
        inset rgba(var(--border-primary), 1) 0 0 0px 1px,
        0 2px 5px -1px rgba(0, 0, 0, 0.2);
    }
    &.--shadow3 {
      box-shadow:
        rgba(var(--border-primary), 1) 0 0 0px 1px,
        inset rgba(var(--border-primary), 1) 0 0 0px 1px,
        0 2px 5px -1px var(--color-variable-1--lighten);
    }
    &.--shadow4 {
      box-shadow:
        rgba(var(--border-primary), 1) 0 0 0px 1px,
        inset rgba(var(--border-primary), 1) 0 0 0px 1px,
        0 25px 50px -15px rgba(0, 0, 0, 0.2);
    }
    &.--shadow5 {
      box-shadow:
        rgba(var(--border-primary), 1) 0 0 0px 1px,
        inset rgba(var(--border-primary), 1) 0 0 0px 1px,
        0 25px 50px -15px var(--color-variable-1--lighten);
    }

    &.--highlighted {
      background-color: transparent;
    }
  }

  &.admin__module--site-header {
    &.--highlighted {
      background-color: var(--menu-background-color);
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(var(--border-global), 0.5);
        z-index: 2;
      }

      &.--actionable {
        &:after {
          display: none;
        }
      }
    }
  }

  .has-iframe,
  &.disable-inside {
    &:after {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.--dragRoot {
    border: 1px solid rgba(16, 21, 32, 1);
    border-radius: 5px;
    box-shadow: 0 0 0 10000em rgba(16, 21, 32, 0.87);
  }
}

// image box has extra wrapper only in admin
.image-box .admin__module.--shape5 {
  border-radius: 50% !important;
}

.admin section,
.admin form {
  overflow: visible !important;
}

// gallery

.gallery-backgroundDrag {
  box-shadow:
    0 4px 13px -3px rgba(0, 19, 48, 0.3),
    0 1px 3px 0 rgba(0, 19, 48, 0.2),
    0 1px 5px 0 rgba(0, 19, 48, 0.2) inset;
}

// reach
[data-reach-listbox-popover] {
  &:focus-within {
    outline: 0;
  }
}

[data-reach-listbox-button] {
  border: 0;
  cursor: pointer;
}

[data-reach-listbox-input] {
  cursor: pointer;
  position: relative;
}

[data-reach-listbox-input] [data-reach-listbox-popover] {
  width: 100%;
}

[data-reach-listbox-popover],
[data-reach-menu-popover],
[data-reach-dialog-list-content] {
  .custom-scrollbar;
  .sh-a;
  .bg-white;
  .text-14-15;
  .rounded-lg;
  .overflow-y-scroll;
  .border-0;
  .z-10;
}

[data-reach-menu-popover] {
  .text-16-17;
  overflow: auto;
}

[data-reach-dialog-list-overlay] {
  .fixed;
  .inset-0;
  .z-10;
  background: rgba(0, 0, 0, 0.33);
}

[data-reach-listbox-popover],
[data-reach-menu-popover] {
  @media screen and (max-width: 639px) {
    &:before {
      .fixed;
      .inset-0;
      background: rgba(0, 0, 0, 0.33);
      content: '';
      pointer-events: none;
    }

    .box-content;
    bottom: 0 !important;
    left: 0 !important;
    max-height: 100vh;
    position: fixed !important;
    right: 0 !important;
    top: auto !important;
    width: 100vw !important;
  }
  @media screen and (min-width: 640px) {
    .box-content;
    max-height: 210px;
    margin-left: -0.5em;
    padding: 0 0.5em;
  }
}

[data-reach-listbox-list],
[data-reach-menu-list],
[data-reach-dialog-list-content] {
  border: none;
  .bg-white;
  @media screen and (max-width: 639px) {
    .m-4;
    .overflow-hidden;
    .rounded-lg;
    .relative;
    &:extend(.space-y-2 all);
  }
  @media screen and (min-width: 640px) {
    .py-2;
  }
}

[data-reach-listbox-option],
[data-reach-menu-item],
[data-reach-dialog-list-option] {
  @media screen and (max-width: 639px) {
    .block;
    .border-solid;
    .border-0;
    .border-gray-200;
    .box-border;
    .p-4;
    .w-full;

    &:not(:last-of-type) {
      .border-b-1;
    }

    &:hover,
    &:focus,
    &[data-current-selected],
    &[data-current-nav] {
      .bg-white;
      .text-black;
    }

    &[data-current-selected] {
      .text-primary;
    }
  }

  @media screen and (min-width: 640px) {
    .grid;
    .gap-x-3;
    .items-center;
    .font-sans;
    .text-black;
    .lh--12;
    .w--500;
    .min-h-10;
    .py-0;
    .px-2;
    .box-border;
    .rounded-md;
    .ls--002;
    .mb-1;
    .cursor-pointer;
    .trans-1-l;

    &:hover,
    &:focus,
    &[data-current-selected],
    &[data-current-nav] {
      .bg-light-blue;
      .text-primary;
      .w--500;
    }
  }
}
