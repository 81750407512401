.text-10-13 {
  font-size: 10px;
  font-size: clamp(10px, 0.77vw, 13px) !important;
}

.text-11 {
  font-size: 11px;
}

.text-12-14 {
  font-size: 12px;
  font-size: clamp(12px, 0.83vw, 14px) !important;
}

.text-13 {
  font-size: 13px;
}

.text-13-17 {
  font-size: 13px;
  font-size: clamp(13px, 0.85vw, 17px) !important;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-14-15 {
  font-size: 14px;
  font-size: clamp(14px, 0.893vw, 15px) !important;
}

.text-14-16 {
  font-size: 14px;
  font-size: clamp(14px, 0.96vw, 16px) !important;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-16-17 {
  font-size: 16px;
  font-size: clamp(16px, 0.96vw, 17px) !important;
}

.text-16-18 {
  font-size: 16px;
  font-size: clamp(16px, 0.9375vw, 18px);
}

.text-16-19 {
  font-size: 16px;
  font-size: clamp(16px, 1.13vw, 19px);
}

.text-16-21 {
  font-size: 16px;
  font-size: clamp(16px, 1.25vw, 21px) !important;
}

.text-17 {
  font-size: 17px;
}

.text-17-19 {
  font-size: 17px;
  font-size: clamp(17px, 1.13vw, 19px) !important;
}

.text-18 {
  font-size: 18px;
}

.text-18-21 {
  font-size: 18px;
  font-size: clamp(18px, 1.25vw, 21px) !important;
}

.text-18-24 {
  font-size: 18px;
  font-size: clamp(18px, 1.4vw, 24px) !important;
}

.text-20 {
  font-size: 20px;
}

.text-20-32 {
  font-size: 30px;
  font-size: clamp(20px, 1.9vw, 32px);
}

.text-22 {
  font-size: 22px;
}

.text-22-38 {
  font-size: 34px;
  font-size: clamp(22px, 2.25vw, 38px);
}

.text-26-28 {
  font-size: 26px;
  font-size: clamp(26px, 1.75vw, 28px);
}

.text-30 {
  font-size: 30px;
}

.text-40 {
  font-size: 40px;
}

.text-48 {
  font-size: 48px;
}
