// gen 2

.u-block {
  background: var(--bg-white);
  color: var(--text-black);
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
}

a.u-block:hover,
.u-block--active {
  box-shadow: 0 0 3px 2px #0c80dd;
}

.u-spacer {
  border-top: 1px solid #eee;
}

// flexbox

.u-flex {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

//
.A_scale-h {
  transition: transform 0.1s ease-in-out, color 0.1s linear;

  @media screen and (min-width: 480px) {
    opacity: 0.75;

    &:hover {
      transform: scale(1.15);
      transition: transform 0.1s ease-in-out, color 0.1s linear;
      opacity: 1;
    }
  }
}
// reponsive

.wide-only {
  @media screen and (max-width: 750px) {
    display: none !important;
  }
}

.narrow-only {
  @media screen and (min-width: 750px) {
    display: none !important;
  }
}

// skyje pro mobil

.m-diable {
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

// grid
.u-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1em;
}

.grid-banner {
  grid-template-columns: 1fr;
  grid-gap: 2vmax 2vw;

  @media screen and (min-width: @xsm-BP) {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
}

.break-all {
  word-break: break-all;
}

// tailwind comapitble
@import 'tw/align.less';
@import 'tw/background.less';
@import 'tw/border.less';
@import 'tw/box-sizing.less';
@import 'tw/cursor.less';
@import 'tw/col-span.less';
@import 'tw/color.less';
@import 'tw/display.less';
@import 'tw/flex-direction.less';
@import 'tw/flex-grow.less';
@import 'tw/flex-shrink.less';
@import 'tw/flex-wrap.less';
@import 'tw/flex.less';
@import 'tw/font-size.less';
@import 'tw/font-family.less';
@import 'tw/gap.less';
@import 'tw/gradient.less';
@import 'tw/grid-cols.less';
@import 'tw/height.less';
@import 'tw/items.less';
@import 'tw/justify.less';
@import 'tw/letter-spacing.less';
@import 'tw/margin.less';
@import 'tw/max-height.less';
@import 'tw/min-height.less';
@import 'tw/max-width.less';
@import 'tw/min-width.less';
@import 'tw/object.less';
@import 'tw/outline.less';
@import 'tw/overflow.less';
@import 'tw/padding.less';
@import 'tw/place-self.less';
@import 'tw/position.less';
@import 'tw/rotate.less';
@import 'tw/space.less';
@import 'tw/text-align.less';
@import 'tw/text-decoration.less';
@import 'tw/transform.less';
@import 'tw/transition.less';
@import 'tw/user-select.less';
@import 'tw/whitespace.less';
@import 'tw/width.less';
@import 'tw/z-index.less';

.h-1px {
  height: 1px;
}

.object-fill {
  object-fit: fill;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.shadow-primary {
  box-shadow: rgba(0, 135, 255, 0.3) 0 0 2.5vw 0.5vw;
}

.uppercase {
  text-transform: uppercase;
}

.smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-25 {
  opacity: 0.25;
}

.cursor-pointer {
  cursor: pointer;
}

.origin-center {
  transform-origin: center;
}

.webkit-1 {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.self-start {
  align-self: flex-start;
}

.mobile--full {
  @media screen and (max-width: @xsm-BP) {
    width: 100%;
    text-align: center;
  }
}

.mr-head {
  margin-right: clamp(12px, 1.8vw, 35px);
}

.hover-trigger {
  @media screen and (min-width: @sm-BP) {
    .hover-target {
      display: none;
    }
    &:hover {
      .hover-target {
        display: block;
      }
    }
  }
}

.hover-trigger-visibility {
  @media screen and (min-width: @sm-BP) {
    .hover-target {
      visibility: hidden;
    }
    &:hover {
      .hover-target {
        visibility: visible;
      }
    }
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.rtl {
  direction: rtl;
}

@media screen and (min-width: 480px) {
  .hover\:svg {
    .element {
      transition: 640ms cubic-bezier(0.19, 1, 0.22, 1);
      transform-origin: center;
    }

    &:hover {
      .element {
        transform: scale(4);
      }
    }
  }
}

.trans-1-l {
  transition: all 0.1s linear;
}

.trans-1-e {
  transition: all 100ms ease;
}

.square {
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.w-90p {
  width: 90px;
  min-width: 90px;

  @media screen and (min-width: 768px) {
    width: auto;
    min-width: 0;
  }
}

.opacity-0 {
  opacity: 0;
}

.sh-a {
  box-shadow: 0 1px 12px 0 rgba(0, 35, 90, 0.08);
}

.sh-b {
  box-shadow: rgb(0, 96, 216, 1) 0 0 0 3px;
}

.sh-c {
  box-shadow: var(--bg-white) 0 0 0 2px, rgba(0, 35, 87, 0.15) 0 6px 12px 0px;
}

.shadow-1 {
  box-shadow: 0 0.5vw 4vw -1vw rgba(54, 121, 255, 0.25);
}

.aspect-video {
  aspect-ratio: 16 / 9;
}
