.c-tablor__preview__wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.3em 0.6em;
  align-items: center;

  @media screen and (min-width: 700px) {
    grid-template-columns: 130px auto;
  }
  @media screen and (max-width: @xsm-BP) {
    justify-items: center;
  }
}

.c-tablor__preview__input {
  .font-size(@font-size: 12, @line: 13);
  letter-spacing: -0.01em;
  font-family: @font1;
  font-weight: 400;
  color: #000;
  padding: 0.8em 1em;
  border: 0;
  border-radius: 6px;
  background-color: #f0f5f8;
}
