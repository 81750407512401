.A_table {
  min-width: 750px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.A_table,
.admin-table {
  width: 100%;
  border-top: 2px solid var(--bg-light-gray);
  border-bottom: 2px solid var(--bg-light-gray);
  font-family: @font1;

  tbody tr {
    td {
      &:first-child {
        .bg-white;
      }
    }
    .bg-white;
    &:nth-child(odd) {
      .bg-main;

      td {
        &:first-child {
          .bg-main;
        }
      }
    }
  }

  tfoot tr {
    td {
      .bg-white !important;
      padding-top: 0.25em;
    }
  }

  thead,
  tbody {
    td,
    th {
      padding: 1.1em 3em 1.1em 0;

      &:first-child {
        padding-left: 1.75em;
      }

      &:last-child {
        padding-right: 1.75em;
      }
    }

    th {
      padding-top: 1.35em;
      vertical-align: bottom;
    }

    td {
      vertical-align: middle;
    }
  }
}

.admin-table {
  thead tr {
    .text-primary;
  }
}
