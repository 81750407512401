.color-palette {
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 7px -3px;
  height: 40px;
  grid-template-columns: 3.5fr 2.5fr 2.5fr 1.5fr;
  padding: 3px;
}

.color-palette--bigger {
  height: 140px;
}

/***********************************************/
/** Color palette definitions ******************/
/***********************************************/

.basic-red {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(28, 32, 44, 1);
  --custom-1: rgba(235, 45, 45, 1);
  --custom-2: var(--dominant);
}

.basic-orange {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(20, 30, 40, 1);
  --custom-1: rgba(245, 100, 30, 1);
  --custom-2: var(--dominant);
}

.basic-yellow {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(23, 27, 32, 1);
  --custom-1: rgba(255, 212, 9, 1);
  --custom-2: var(--dominant);
}

.basic-green {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(25, 45, 50, 1);
  --custom-1: rgba(85, 205, 90, 1);
  --custom-2: var(--dominant);
}

.basic-blue {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(20, 30, 40, 1);
  --custom-1: rgba(50, 105, 245, 1);
  --custom-2: var(--dominant);
}

.basic-purple {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(20, 30, 40, 1);
  --custom-1: rgba(120, 90, 200, 1);
  --custom-2: var(--dominant);
}

.blue-yellow {
  --dominant: rgba(247, 246, 237, 1);
  --supplementary: rgba(48, 56, 77, 1);
  --custom-1: rgba(215, 180, 25, 1);
  --custom-2: var(--dominant);
}

.business-gold {
  --dominant: rgba(48, 56, 77, 1);
  --supplementary: rgba(247, 246, 237, 1);
  --custom-1: rgba(203, 179, 45, 1);
  --custom-2: var(--dominant);
}

.luxury-cream {
  --dominant: rgba(246, 245, 241, 1);
  --supplementary: rgba(21, 29, 37, 1);
  --custom-1: rgba(157, 150, 140, 1);
  --custom-2: var(--dominant);
}

.metallic-red {
  --dominant: rgba(237, 242, 244, 1);
  --supplementary: rgba(43, 45, 66, 1);
  --custom-1: rgba(239, 35, 60, 1);
  --custom-2: var(--dominant);
}

.deepblue-silver {
  --dominant: rgba(14, 14, 30, 1);
  --supplementary: rgba(255, 255, 255, 1);
  --custom-1: rgba(91, 92, 105, 1);
  --custom-2: rgba(69, 70, 83, 1);
}

.pastel-purple {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(33, 62, 140, 1);
  --custom-1: rgba(43, 112, 222, 1);
  --custom-2: rgba(200, 73, 105, 1);
}

.neon-purple {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(58, 12, 163, 1);
  --custom-1: rgba(247, 37, 133, 1);
  --custom-2: rgba(76, 201, 240, 1);
}

.blue-orange {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(0, 48, 73, 1);
  --custom-1: rgba(247, 127, 0, 1);
  --custom-2: rgba(214, 40, 40, 1);
}

.silver-leather {
  --dominant: rgba(75, 51, 47, 1);
  --supplementary: rgba(255, 255, 255, 1);
  --custom-1: rgba(111, 115, 123, 1);
  --custom-2: rgb(88, 92, 99);
}

.silver-black {
  --dominant: rgba(21, 21, 21, 1);
  --supplementary: rgba(255, 255, 255, 1);
  --custom-1: rgba(146, 148, 152, 1);
  --custom-2: rgb(112, 114, 117);
}

.basic-black {
  --dominant: rgba(0, 0, 0, 1);
  --supplementary: rgba(255, 255, 255, 1);
  --custom-1: rgba(48, 48, 48, 1);
  --custom-2: var(--dominant);
}

.basic-white {
  --dominant: rgba(255, 255, 255, 1);
  --supplementary: rgba(0, 0, 0, 1);
  --custom-1: rgba(48, 48, 48, 1);
  --custom-2: var(--dominant);
}

.creamy-green {
  --dominant: rgba(41, 50, 55, 1);
  --supplementary: rgba(228, 232, 235, 1);
  --custom-1: rgba(177, 176, 171, 1);
  --custom-2: var(--dominant);
}

.luxury-burgundy {
  --dominant: rgba(81, 29, 29, 1);
  --supplementary: rgba(251, 249, 240, 1);
  --custom-1: rgba(183, 169, 138, 1);
  --custom-2: var(--dominant);
}

.orange-green {
  --dominant: rgba(38, 70, 83, 1);
  --supplementary: rgba(255, 255, 255, 1);
  --custom-1: rgba(231, 111, 81, 1);
  --custom-2: rgba(233, 196, 106, 1);
}

/***********************************************/
/***********************************************/
/***********************************************/

.dominant {
  background-color: var(--dominant);
}
.supplementary {
  background-color: var(--supplementary);
}
.custom--1 {
  background-color: var(--custom-1);
}
.custom--2 {
  background-color: var(--custom-2);
}
