.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

@media screen and (min-width: @md-BP) {
  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-row {
    flex-direction: row;
  }
}
