.pt-3 {
  padding-top: 0.75em !important;
}

.py-0 {
  padding-top: 0em !important;
  padding-bottom: 0em !important;
}

.py-1 {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
}

.py-2 {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.py-3 {
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
}

.py-4 {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.py-5 {
  padding-top: 1.25em !important;
  padding-bottom: 1.25em !important;
}

.py-6 {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
}

.py-7 {
  padding-top: 1.75em !important;
  padding-bottom: 1.75em !important;
}

.py-15 {
  padding-top: 3.75em !important;
  padding-bottom: 3.75em !important;
}

.pt-7 {
  padding-top: 1.75em !important;
}

.pb-1 {
  padding-bottom: 0.25em !important;
}

.pb-3 {
  padding-bottom: 0.75em !important;
}

.pb-4 {
  padding-bottom: 1em !important;
}

.pb-5 {
  padding-bottom: 1.25em !important;
}

.pb-7 {
  padding-bottom: 1.75em !important;
}

.pb-100pc {
  padding-bottom: 100%;
}

.px-20vw {
  padding-left: 5vw;
  padding-right: 5vw;
}

.px-1 {
  padding-left: 0.25em !important;
  padding-right: 0.25em !important;
}

.px-2 {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.px-3 {
  padding-left: 0.75em !important;
  padding-right: 0.75em !important;
}

.px-4 {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.px-5 {
  padding-left: 1.25em !important;
  padding-right: 1.25em !important;
}

.py-20 {
  padding-top: 4em !important;
  padding-bottom: 4em !important;
}

.px-6 {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.px-7 {
  padding-left: 1.75em !important;
  padding-right: 1.75em !important;
}

.px-8 {
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.px-10 {
  padding-left: 2.5em !important;
  padding-right: 2.5em !important;
}

.px-auto {
  padding-left: unset !important;
  padding-right: unset !important;
}

.pr-0 {
  padding-right: 0;
}

.pr-2 {
  padding-right: 0.5em !important;
}

.pr-6 {
  padding-right: 1.5em !important;
}

.pl-2 {
  padding-left: 0.5em !important;
}

.pl-6 {
  padding-left: 1.5em !important;
}

.pl-12 {
  padding-left: 3em !important;
}

.pl-13 {
  padding-left: 3.25em !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25em !important;
}

.p-2 {
  padding: 0.5em;
}

.p-3 {
  padding: 0.75em !important;
}

.p-4 {
  padding: 1em !important;
}

.p-5 {
  padding: 1.25em !important;
}

.p-6 {
  padding: 1.5em !important;
}

.p-7 {
  padding: 1.75em !important;
}

.p-8 {
  padding: 2em !important;
}

.p-10 {
  padding: 2.5em !important;
}

.p-11 {
  padding: 2.75em !important;
}

.p-13 {
  padding: 3.25em !important;
}

@media screen and (min-width: @sm-BP) {
  .sm\:p-10 {
    padding: 2.5em !important;
  }

  .sm\:p-12 {
    padding: 3em !important;
  }

  .sm\:p-0 {
    padding: 0em !important;
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm\:px-8 {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .sm\:px-10 {
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
  }

  .sm\:py-4 {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }

  .sm\:py-6 {
    padding-top: 1.5em !important;
    padding-bottom: 1.5em !important;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:py-8 {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }

  .lg\:px-14 {
    padding-left: 5em !important;
    padding-right: 5em !important;
  }

  .lg\:pt-10 {
    padding-top: 2.5em !important;
  }
  .lg\:px-4 {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}

@media screen and (min-width: @md-BP) {
  .md\:p-0 {
    padding: 0 !important;
  }
}
