.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

.items-baseline {
  align-items: baseline;
}

.self-stretch {
  align-self: stretch;
}

.self-center {
  align-self: center;
}

@media screen and (min-width: @md-BP) {
  .md\:items-center {
    align-items: center;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:items-center {
    align-items: center;
  }
}
