.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media screen and (min-width: @sm-BP) {
  .sm\:text-left {
    text-align: left;
  }
}

@media screen and (min-width: @md-BP) {
  .md\:text-left {
    text-align: left;
  }
}

@media screen and (min-width: @lg-BP) {
  .lg\:text-left {
    text-align: left;
  }
}
