body,
html {
  width: 100%;
  min-height: 100%;
  .ff--1;
  //Resi mensí zobrazeni textu pro mobil
  font-size: 14px;

  @media screen and (min-width: @xsm-BP) {
    font-size: 16px;
  }
}

img,
svg {
  vertical-align: bottom;
}

strong {
  font-weight: 600;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  background: transparent;
  outline: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
}

sup {
  vertical-align: super;
  font-size: smaller;
}
