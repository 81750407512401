.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-center {
  object-position: center;
}
