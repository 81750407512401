.drop-zone {
  position: relative;
  text-align: center;
  transition: all 150ms linear;
  width: 100%;

  &--is-dragover {
    border: 2px solid @color-variable-1!important;
  }

  &__file {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  label {
    cursor: pointer;
    display: block;
    padding: 6em 1em 6em 1em;
  }

  @media (max-width: 660px) {
    border: none;

    label {
      display: inline-block;
      padding: 0;
    }
  }
}
